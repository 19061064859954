import { PageNavigation } from "@components/cms/page-navigation/PageNavigation";
import USPbanner from "@components/ui/USPbanner/USPbanner";
import type { ISiteSettings } from "@contentful-api/types/contentful";
import type { DefaultPageProps } from "@interfaces/PageInterfaces";
import { useAuth } from "@lib/auth/useAuth";
import { useStore } from "@store/index";
import { deserialize } from "@ungap/structured-clone";
import dynamic from "next/dynamic";
import type { PropsWithChildren, ReactNode } from "react";
import Footer from "./footer/Footer";

const Navigation = dynamic(() => import("./navigation/Navigation").then((mod) => mod.Navigation));

export type MyAccountLayoutProps = {
    siteSettings: ISiteSettings;
    children?: ReactNode;
    pageProps: DefaultPageProps;
};

export const MyAccountLayout = ({
    siteSettings,
    children,
    pageProps,
}: PropsWithChildren<MyAccountLayoutProps>) => {
    const { isAuthenticated } = useAuth();

    const { serializedPage } = pageProps;
    const customer = useStore((state) => state.data.me?.customer);

    const firstName = customer?.firstName || "";
    const lastName = customer?.lastName || "";
    const navigationTitle = `${firstName} ${lastName}`.trim();

    const page = deserialize(serializedPage);
    const pageNavigationLinks = page?.fields?.pageNavigation;

    return (
        <>
            {siteSettings?.fields?.uspBanner && (
                <USPbanner uspBanner={siteSettings?.fields?.uspBanner} />
            )}
            <Navigation navigation={siteSettings?.fields?.mainNavigation} />
            <div data-testid="MyAccountPage">
                {pageNavigationLinks && isAuthenticated && (
                    <PageNavigation
                        isPrivateDataInTitle
                        pageNavigationLinks={pageNavigationLinks}
                        title={navigationTitle}
                    />
                )}
                {children}
            </div>
            <Footer footerData={siteSettings?.fields?.footer} />
        </>
    );
};
