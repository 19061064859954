import { Portal } from "@ui/components/layout/portal/Portal";
import {
    containerStyles,
    desktopNavWrapperStyles,
    iconWrapperStyles,
    linksContainerStyles,
    logoContainerStyles,
    mobileLogoContainerStyles,
    navContainerStyles,
    rightContainerStyles,
    searchIconWrapperStyles,
} from "@components/layouts/navigation/styles";
import type { INavigation } from "@contentful-api/types/contentful";
import { IconButton } from "@ui/components/forms/icon-button/IconButton";
import { Box } from "@ui/components/layout/box/Box";
import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { Container } from "@ui/components/layout/container/Container";
import { Flex } from "@ui/components/layout/flex/Flex";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { ECCOLogo } from "@ui/components/media-and-icons/ecco-logo/ECCOLogo";
import { useThemeMediaQuery } from "@ui/hooks/useThemeMediaQuery";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { sizes, space } from "ui/src/design-tokens";
import RightNavigation from "./components/RightNavigation";
import { DesktopNav } from "./desktop/DesktopNav";
import { MobileNavigationContainer } from "./mobile/MobileNavigationContainer";
import useIsomorphicLayoutEffect from "@ui/hooks/useIsomorphicLayoutEffect";
import { VisuallyHidden } from "@ui/components/other/visually-hidden/VisuallyHidden";
import dynamic from "next/dynamic";
import { NextLink } from "@ui/components/navigation/link/NextLink";
import { MOBILE_OPEN_SEARCH_ICON } from "@e2e/helpers/navigation/constants";
import { SEARCHBAR_INPUT } from "@e2e/helpers/homepage/constants";

export interface NavigationProps {
    navigation: INavigation;
    isProductPage?: boolean;
}

const SearchBarLazy = dynamic(
    () => import("@components/layouts/navigation/search/SearchBar").then((mod) => mod.SearchBar),
    {
        ssr: false,
    }
);

export const Navigation: FC<NavigationProps> = ({ navigation, isProductPage = false }) => {
    const { get: getMicrocopy } = useMicrocopy();
    const [clientIsDesktop, setClientIsDesktop] = useState<boolean>(true);
    const [searchBarOpen, setSearchBarOpen] = useState<boolean>(false);
    const { events } = useRouter();
    const { xl } = useThemeMediaQuery();

    // depending of window, will trigger useLayoutEffect or useEffect. This will run on the client before the DOM is painted, if we don't do this check we will get a warning on the server console.
    useIsomorphicLayoutEffect(() => {
        const timeout = setTimeout(() => setClientIsDesktop(xl), 100);
        return () => clearTimeout(timeout);
    }, [xl]);

    useEffect(() => {
        events.on("routeChangeComplete", () => {
            setSearchBarOpen(false);
        });
        return () => {
            events.off("routeChangeComplete", () => {
                setSearchBarOpen(false);
            });
        };
    }, [events, setSearchBarOpen]);

    const homeLink = (
        <NextLink
            href="/"
            aria-label={getMicrocopy(CommonCMS.shared, CommonCMS.ariaLabels.homeButtonAria)}
            data-testid="homelink"
            display="flex"
            minW="eccoLogo"
            h={sizes.icon["md"]}
            _hover={{ color: "gray.500" }}
        >
            <ECCOLogo />
        </NextLink>
    );
    const skipLinkText =
        getMicrocopy(CommonCMS.shared, CommonCMS.ariaLabels.skipLinkTextAria) ||
        "Skip to main Content";
    const navigationMemo = useMemo(() => {
        return (
            <>
                <VisuallyHidden>
                    <a id="primarySkipLink" href="#skipLinkNavPages">
                        {skipLinkText}
                    </a>
                </VisuallyHidden>
                <Container
                    as={"nav"}
                    aria-label={
                        getMicrocopy(CommonCMS.shared, CommonCMS.ariaLabels.navigationAria) ||
                        "Main navigation"
                    }
                    id="navigation"
                    data-testid="mainMenu"
                    sx={{ ...navContainerStyles }}
                >
                    <Box data-testid="Navigation" sx={containerStyles}>
                        <Flex w="100%" display={{ base: "flex", xl: "none" }}>
                            <MobileNavigationContainer
                                navigation={navigation}
                                searchBarOpen={searchBarOpen}
                                isProductPage={isProductPage}
                                clientIsDesktop={clientIsDesktop}
                            />
                            <Flex sx={mobileLogoContainerStyles}>{homeLink}</Flex>
                        </Flex>
                        {clientIsDesktop && (
                            <Flex sx={desktopNavWrapperStyles}>
                                <>
                                    <Flex sx={logoContainerStyles}>{homeLink}</Flex>
                                    <Flex sx={linksContainerStyles}>
                                        <DesktopNav navigation={navigation} />
                                    </Flex>
                                </>
                            </Flex>
                        )}

                        <Flex sx={iconWrapperStyles}>
                            <>
                                <Flex
                                    tabIndex={0}
                                    data-testid={MOBILE_OPEN_SEARCH_ICON}
                                    width={searchBarOpen ? ["100%"] : "auto"}
                                    height="100%"
                                    position={searchBarOpen ? "absolute" : "static"}
                                    top={0}
                                    left={0}
                                    onFocus={() => {
                                        setSearchBarOpen(true);
                                    }}
                                    onClick={(ev) => {
                                        const target = ev.target as HTMLElement;
                                        const isSearchInput =
                                            target.getAttribute("data-testId") === SEARCHBAR_INPUT;
                                        if (searchBarOpen && !isSearchInput) {
                                            setSearchBarOpen(false);
                                            return;
                                        }
                                        setSearchBarOpen(true);
                                    }}
                                    gap={space["0.2"]}
                                    justifyContent="flex-end"
                                >
                                    <Flex
                                        margin={searchBarOpen ? "0 auto" : "0 0 0 0"}
                                        width={searchBarOpen ? "100%" : "auto"}
                                        h={sizes.icon["4xs"]}
                                        w={sizes.icon["4xs"]}
                                        bg="white"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={searchIconWrapperStyles}
                                    >
                                        <ECCOIcon name="search" />
                                        {!clientIsDesktop && (
                                            <SearchBarLazy searchBarOpen={searchBarOpen} />
                                        )}
                                    </Flex>
                                    {searchBarOpen && (
                                        <Box
                                            width="auto"
                                            display="flex"
                                            h={sizes.icon["4xs"]}
                                            justifyContent="center"
                                            zIndex={2}
                                            borderLeft="1px solid"
                                            borderColor="gray.500"
                                        >
                                            <IconButton
                                                w={sizes["10.4"]}
                                                h={sizes.icon["4xs"]}
                                                zIndex={3}
                                                bg="white"
                                                borderRadius="0"
                                                aria-label={getMicrocopy(
                                                    CommonCMS.shared,
                                                    CommonCMS.ariaLabels.closeModalAria
                                                )}
                                                icon={<ECCOIcon name="close" />}
                                                variant="tertiary"
                                                onKeyUp={(e) => {
                                                    if (e.key === "Enter") {
                                                        setSearchBarOpen(false);
                                                    }
                                                }}
                                                onMouseUp={() => setSearchBarOpen(false)}
                                            />
                                        </Box>
                                    )}
                                </Flex>
                                {!clientIsDesktop && (
                                    <Portal>
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                backgroundColor: "rgba(0,0,0,0.6)",
                                                opacity: searchBarOpen ? 1 : 0,
                                                visibility: searchBarOpen ? "visible" : "hidden",
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                zIndex: 3,
                                            }}
                                        ></Box>
                                    </Portal>
                                )}
                            </>

                            <Flex
                                sx={{
                                    ...rightContainerStyles,
                                    "& svg": {
                                        color: searchBarOpen ? "transparent" : "currentColor",
                                        transition: "color .4s ease",
                                    },
                                }}
                            >
                                <RightNavigation />
                            </Flex>
                        </Flex>
                    </Box>
                </Container>
                <div aria-hidden="true" id="skipLinkNavPages"></div>
            </>
        );
    }, [clientIsDesktop, isProductPage, navigation, searchBarOpen]);

    return <>{navigationMemo}</>;
};
