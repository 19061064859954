import { useGlobalModalContext } from "@components/GlobalModalProvider";
import { useAuth } from "@lib/auth/useAuth";
import {
    getPagePathByLocale,
    myAccountPage,
    myAccountWishlistPage,
    shoppingBagPage,
    storeLocatorPage,
} from "@lib/constants/pagePaths";
import { ModalTypes } from "@lib/enums/ModalTypes";
import { useStore } from "@store/index";
import { useFreshData } from "@store/hooks";
import { IconButton } from "@ui/components/forms/icon-button/IconButton";
import { Box } from "@ui/components/layout/box/Box";
import { Circle } from "@ui/components/layout/center/Circle";
import { Flex } from "@ui/components/layout/flex/Flex";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { NextLink } from "@ui/components/navigation/link/NextLink";
import { useThemeMediaQuery } from "@ui/hooks/useThemeMediaQuery";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { sizes, space } from "ui/src/design-tokens";
import { getLineItemsQuantityWithoutGiftWrapping } from "@lib/utils/getLineItemsQuantityWithoutGiftWrapping";

const RightNavigation = () => {
    const cart = useStore((state) => state.data.me.cartInfo?.cart);
    const wishlist = useStore((state) => state.data.me.wishlistInfo?.wishlist);
    const { sm } = useThemeMediaQuery();
    const { push, locale } = useRouter();
    useFreshData({});
    const [iconSize, setIconSize] = useState("sm");
    const { isAuthenticated } = useAuth();
    const { showModal } = useGlobalModalContext();

    const productCount = getLineItemsQuantityWithoutGiftWrapping(cart);
    //@ts-ignore
    const productWishlistCount = wishlist?.lineItems?.filter(
        (item) => item.variant !== null
    ).length;

    const handleAccountClick = () => {
        if (!isAuthenticated) return showModal(ModalTypes.createAccount);
        return push(getPagePathByLocale(myAccountPage, locale));
    };

    useEffect(() => {
        if (sm) {
            setIconSize("sm");
        } else {
            setIconSize("md");
        }
    }, [sm]);
    //TODO: Intl
    const profileLabel = isAuthenticated ? "Go to Your Account" : "Create Account Or Login";

    return (
        <Flex
            gap={[space["0.2"], space["0.2"], space["0.2"], space["0.2"], "0"]}
            data-testid="RightNavigationLayout"
        >
            <IconButton
                data-testid="MenuAccountIcon"
                aria-label={profileLabel}
                bg={"black"}
                color={"gray.150"}
                h={sizes.navBarCollapsedHeight}
                w={"10.4"}
                variant={"tertiary"}
                icon={<ECCOIcon name="account" />}
                size={iconSize}
                display={["none", "none", "none", "none", "flex"]}
                _hover={{ color: "black" }}
                cursor={"pointer"}
                onClick={handleAccountClick}
            />
            <IconButton
                data-testid="MenuWishlistIcon"
                as={NextLink}
                href={getPagePathByLocale(myAccountWishlistPage, locale)}
                aria-label={`Wishlist ${productWishlistCount} items`}
                bg={["white", "white", "white", "white", "black"]}
                color={["black", "black", "black", "black", "gray.150"]}
                variant={"tertiary"}
                display={"flex"}
                h={sizes.navBarCollapsedHeight}
                w={"10.4"}
                _hover={{
                    color: "black",
                    // We need these styles for the circle on hover desktop
                    "& div div": {
                        bg: "black",
                        color: "white",
                    },
                }}
                borderColor="none"
                boxShadow="right"
                icon={
                    <Box position={"relative"}>
                        <ECCOIcon name="wishlist" />
                        {productWishlistCount > 0 && (
                            <Circle
                                size={{ base: "14px", xl: `12px` }}
                                fontSize={{ base: "10px", xl: "8px" }}
                                bg={{ base: "black", xl: "white" }}
                                color={{ base: "white", xl: "black" }}
                                position={"absolute"}
                                ml={"13px"}
                                mt={"-13px"}
                                fontWeight={"extrabold"}
                            >
                                {productWishlistCount}
                            </Circle>
                        )}
                    </Box>
                }
                size={iconSize}
            />
            <IconButton
                data-testid="MenuStoreLocatorIcon"
                as={NextLink}
                href={getPagePathByLocale(storeLocatorPage, locale)}
                aria-label={"Store"}
                color={"gray.150"}
                bg={"black"}
                h={sizes.navBarCollapsedHeight}
                w={"10.4"}
                variant={"tertiary"}
                icon={<ECCOIcon name="store-locator" />}
                size={iconSize}
                display={["none", "none", "none", "none", "flex"]}
                _hover={{ color: "black" }}
            />
            <IconButton
                data-testid="MenuShoppingBagIcon"
                as={NextLink}
                href={getPagePathByLocale(shoppingBagPage, locale)}
                aria-label={`Shopping Bag ${productCount} items`}
                bg={["white", "white", "white", "white", "black"]}
                color={["black", "black", "black", "black", "gray.150"]}
                h={sizes.navBarCollapsedHeight}
                w={"10.4"}
                variant={"tertiary"}
                display={"flex"}
                _hover={{
                    color: "black",
                    // We need these styles for the circle on hover desktop
                    "& div div": {
                        bg: "black",
                        color: "white",
                    },
                }}
                icon={
                    <Box position={"relative"}>
                        <ECCOIcon name="bag" />
                        {productCount && (
                            <Circle
                                size={{ base: "14px", xl: `12px` }}
                                fontSize={{ base: "10px", xl: "8px" }}
                                bg={{ base: "black", xl: "white" }}
                                color={{ base: "white", xl: "black" }}
                                position={"absolute"}
                                ml={"13px"}
                                mt={"-13px"}
                                fontWeight={"extrabold"}
                            >
                                {productCount}
                            </Circle>
                        )}
                    </Box>
                }
                size={iconSize}
            />
        </Flex>
    );
};

export default RightNavigation;
