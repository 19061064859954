export const styles = {
    placeholder: {
        alignItems: "center",
        color: "gray.500",
        px: 3,
        gap: 2,
        transitionProperty: "common",
        transitionDuration: "normal",
        maxW: "200px",
        _hover: {
            // This specific behaviour is not defined in the design system
            bg: "gray.100",
            "& svg, & p": {
                color: "black",
            },
        },
    },
    placeHolderText: {
        fontWeight: "bold",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
};
