import type { INavigationItem } from "@contentful-api/types/contentful";
import { Flex } from "@ui/components/layout/flex/Flex";
import type { FC } from "react";
import { CategoryTile } from "./CategoryTile";

interface IFullStoryTiles {
    thumbnailLinksTiles: INavigationItem["fields"]["thumbnailLinksTiles"];
    isVisible?: boolean;
}

export const FullStoryTiles: FC<IFullStoryTiles> = ({ thumbnailLinksTiles, isVisible }) => {
    return (
        <Flex w="100%" gap={10} mb={16}>
            {thumbnailLinksTiles?.map((link, i) => {
                const key = `${link?.sys?.id} - ${i}`;

                return (
                    <CategoryTile
                        key={key}
                        link={link}
                        imageSizes={["50vw"]}
                        isVisible={isVisible}
                    />
                );
            })}
        </Flex>
    );
};
