import {
    animatedContainerStyles,
    bottomContainerLinkStyles,
    innerNavLeftGridStyles,
    innerNavRightStyles,
    menuContainerStyles,
    navItemContainerStyles,
    navItemStyles,
    outerNavLeftStyles,
    salesNavItemContainerStyles,
    salesNavItemStyles,
} from "@components/layouts/navigation/styles";
import type { ILinksCollection, INavigationItem } from "@contentful-api/types/contentful";
import { useFocus } from "@hooks/useFocus";
import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { Button } from "@ui/components/forms/button/Button";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { Grid, GridItem } from "@ui/components/layout/grid/Grid";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { VisuallyHidden } from "@ui/components/other/visually-hidden/VisuallyHidden";
import { useBoundingRect } from "@ui/hooks/useBoundingRect";
import { useDisclosure } from "@ui/hooks/useDisclosure";
import { isEmpty } from "lodash-es";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { CategoryTile } from "../components/CategoryTile";
import { FallbackImageNavigation } from "../components/FallbackImageNavigation";
import { FullStoryTiles } from "../components/FullStoryTiles";
import { ThumbnailGrid } from "../components/ThumbnailGrid";
import { DesktopSubNavItem } from "./DesktopSubNavItem";
import { Link } from "@components/cms/link/Link";

export interface DesktopNavItemProps {
    navigationItem: INavigationItem;
    isOpen: boolean;
    setHeightNav: (height: number) => void;
    heightNav: number;
    onOpen: () => void;
    onClose: () => void;
    searchBarOpen?: boolean;
    isHoveringNavBar?: boolean;
    isSalesNavigationItem?: boolean;
}

const filterByColumns = (columns: ILinksCollection[], columnNumber: number) => {
    return columns
        ?.map(
            (linkCollection) =>
                linkCollection?.fields?.column === String(columnNumber) && (
                    <DesktopSubNavItem
                        key={linkCollection?.sys?.id}
                        title={linkCollection?.fields?.title}
                        titleLink={linkCollection?.fields?.titleLink}
                        links={linkCollection?.fields?.links}
                    />
                )
        )
        .filter((item) => Boolean(item));
};

const checkFourColumns = (array = []) => {
    for (const item of array) {
        if (item.fields.column === "4") {
            return true;
        }
    }
    return false;
};

export const DesktopNavItem = ({
    navigationItem,
    isOpen,
    heightNav,
    setHeightNav,
    searchBarOpen,
    onOpen: onOpenParent,
    onClose: onCloseParent,
    isHoveringNavBar,
    isSalesNavigationItem,
}: DesktopNavItemProps) => {
    const { onOpen, onClose, isOpen: isOpenInner } = useDisclosure({ defaultIsOpen: false });
    const { get: getMicrocopy } = useMicrocopy();
    const [openButton, setOpenButtonFocus] = useFocus();
    const [closeButton, setCloseButtonFocus] = useFocus();

    const goToCloser = () => {
        toggleOpen();
        setCloseButtonFocus();
    };
    const goToOpener = () => {
        toggleOpen();
        setOpenButtonFocus();
    };
    const toggleOpen = () => {
        if (isOpen) {
            onClose();
            return;
        }
        onOpen();
    };

    const getNavigationStyles = (isSalesNavigationItem?: boolean) => ({
        navItemContainerStyles: isSalesNavigationItem
            ? salesNavItemContainerStyles
            : navItemContainerStyles,
        navItemStyles: isSalesNavigationItem ? salesNavItemStyles : navItemStyles,
    });
    const { asPath } = useRouter();
    useEffect(() => {
        if (!isOpen && isOpenInner) {
            onOpenParent();
        }
    }, [isOpen, isOpenInner, onOpenParent]);
    const fields = navigationItem?.fields;
    const {
        fallbackImage,
        linkCollections,
        primaryLink,
        thumbnailLinksTiles,
        thumbnailLinks,
        bottomLinkLabel,
    } = fields || {};

    const columns = Array.isArray(linkCollections) ? linkCollections : [];
    const hasFourColumns = checkFourColumns(columns);
    const primeFields = primaryLink?.fields;
    const categoryLabel = primeFields?.label;

    const GridColumnsList = () => {
        return (
            <Grid
                templateColumns="repeat( auto-fit, minmax(220px, 1fr) )"
                h="100%"
                w="100%"
                sx={innerNavLeftGridStyles}
            >
                {/* Left Section */}
                <GridItem colStart={1}>{filterByColumns(columns, 1)}</GridItem>
                <GridItem colStart={2}>{filterByColumns(columns, 2)}</GridItem>
                <GridItem colStart={3}>{filterByColumns(columns, 3)}</GridItem>
                <GridItem colStart={4}>{filterByColumns(columns, 4)}</GridItem>
            </Grid>
        );
    };

    const { ref: navigationRef, dimensions: navigationDimensions } = useBoundingRect({});
    const { ref: innerContainerRef, dimensions: innerContainerDimensions } = useBoundingRect({});

    useEffect(() => {
        if (!isOpen) {
            setHeightNav(0);
            return;
        }
        if (isOpenInner) {
            setHeightNav(innerContainerDimensions.height);
        }
    }, [innerContainerDimensions.height, isOpenInner, setHeightNav, isOpen]);

    return (
        <Flex
            data-testid="DesktopNavItem"
            onMouseEnter={isHoveringNavBar ? onOpen : undefined}
            onMouseLeave={onClose}
            align="center"
            sx={getNavigationStyles(isSalesNavigationItem).navItemContainerStyles}
        >
            <Link
                link={fields?.primaryLink}
                sx={{
                    ...getNavigationStyles(isSalesNavigationItem).navItemStyles,
                    opacity: searchBarOpen ? 0 : 1,
                    transition: "opacity .4s ease-in-out",
                }}
                data-testid={primeFields?.pageTypeId}
                onClick={() => {
                    if (primeFields?.completeSlug !== asPath) {
                        onClose();
                        onCloseParent();
                        setHeightNav(0);
                    }
                }}
            >
                {categoryLabel}
            </Link>
            <VisuallyHidden>
                <Button tabIndex={-1} ref={openButton} onClick={goToCloser} data-testid="enterMenu">
                    {getMicrocopy(CommonCMS.shared, CommonCMS.navigationMenu.ariaButtonOpenMenu, {
                        replacements: {
                            categoryName: categoryLabel,
                        },
                    })}
                </Button>
            </VisuallyHidden>
            {/*  Big container desktop with animation  */}
            <Box
                ref={navigationRef}
                zIndex={isOpenInner ? 10 : 0}
                opacity={isOpenInner ? 1 : 0}
                visibility={isOpenInner ? "visible" : "hidden"}
                sx={{
                    ...animatedContainerStyles,
                    transition:
                        isOpen && "opacity .01s .25s ease-in-out, visibility .5s .25s ease-in-out",
                }}
            >
                <Box
                    ref={innerContainerRef}
                    maxW="unset"
                    sx={{
                        ...menuContainerStyles,
                        opacity: isOpen ? 1 : 0,
                        transition: isOpenInner && "opacity .25s .30s ease-in-out",
                    }}
                    onClick={() => {
                        onCloseParent();
                        onClose();
                    }}
                >
                    <VisuallyHidden>
                        <Button
                            tabIndex={-1}
                            ref={closeButton}
                            onClick={goToOpener}
                            data-testid="exitMenu"
                        >
                            {getMicrocopy(
                                CommonCMS.shared,
                                CommonCMS.navigationMenu.ariaButtonCloseMenu,
                                {
                                    replacements: {
                                        categoryName: categoryLabel,
                                    },
                                }
                            )}
                        </Button>
                    </VisuallyHidden>
                    {/* When there is no links, will try to create a layout 50/50 with Thumbnail Links Tiles */}
                    {isEmpty(linkCollections) && isEmpty(thumbnailLinks) ? (
                        <FullStoryTiles
                            thumbnailLinksTiles={thumbnailLinksTiles}
                            isVisible={isOpen}
                        />
                    ) : (
                        <>
                            <Box sx={{ ...outerNavLeftStyles }}>
                                {!isEmpty(thumbnailLinks) ? (
                                    <ThumbnailGrid
                                        thumbnailLinks={thumbnailLinks}
                                        isVisible={isOpen}
                                    />
                                ) : (
                                    <GridColumnsList />
                                )}
                                {bottomLinkLabel && isEmpty(thumbnailLinks) && (
                                    <Link sx={bottomContainerLinkStyles} link={fields?.primaryLink}>
                                        {bottomLinkLabel}
                                        <ECCOIcon name="arrow-right" />
                                    </Link>
                                )}
                            </Box>
                            {/* Right section */}
                            <Flex
                                sx={{
                                    ...innerNavRightStyles,
                                    "& > :first-of-type": {
                                        opacity: isOpen ? 1 : 0,
                                        visibility: isOpen ? "visible" : "hidden",
                                        transition:
                                            isOpen &&
                                            "opacity .25s .30s ease-in-out, visibility .5s",
                                    },
                                    "& > :not(:first-of-type)": {
                                        opacity:
                                            isOpen && heightNav === navigationDimensions.height
                                                ? 1
                                                : 0,
                                        visibility:
                                            isOpen && heightNav === navigationDimensions.height
                                                ? "visible"
                                                : "hidden",
                                        transition:
                                            heightNav === navigationDimensions.height &&
                                            isOpenInner &&
                                            "opacity .25s .40s ease-in-out, visibility .5s .30s ease-in-out",
                                    },
                                    maxW: hasFourColumns ? "25%" : "50%",
                                    display: hasFourColumns
                                        ? ["none", "none", "none", "none", "flex"]
                                        : "flex",
                                }}
                            >
                                {!isEmpty(thumbnailLinksTiles) ? (
                                    thumbnailLinksTiles?.map((link) => {
                                        return (
                                            <CategoryTile
                                                key={link.sys.id}
                                                link={link}
                                                imageSizes={["100vw", "100vw", "100vw", "50vw"]}
                                                isVisible={isOpen}
                                            />
                                        );
                                    })
                                ) : (
                                    <FallbackImageNavigation
                                        url={fallbackImage?.fields?.file?.url}
                                    />
                                )}
                            </Flex>
                        </>
                    )}
                </Box>
            </Box>
        </Flex>
    );
};
