import { CategoryTile } from "./CategoryTile";
import { Grid, GridItem } from "@ui/components/layout/grid/Grid";
import type { INavigationItem } from "@contentful-api/types/contentful";
import type { FC } from "react";
import { isEmpty } from "lodash-es";
import { innerNavLeftGridStyles } from "@components/layouts/navigation/styles";

interface IThumbnailGrid {
    thumbnailLinks: INavigationItem["fields"]["thumbnailLinks"];
    isVisible?: boolean;
}

export const ThumbnailGrid: FC<IThumbnailGrid> = ({ thumbnailLinks, isVisible = false }) => {
    if (isEmpty(thumbnailLinks)) {
        return null;
    }

    return (
        <Grid
            sx={innerNavLeftGridStyles}
            templateColumns={{
                lg: "repeat(2, 1fr)",
                xl: "repeat(2, 1fr)",
            }}
            pr={{ base: 2, xl: 3 }}
        >
            {thumbnailLinks.map((link) => (
                <GridItem w="100%" key={link.sys?.id}>
                    <CategoryTile
                        isLeftSide={true}
                        link={link}
                        imageSizes={["50vw", "50vw", "25vw", "25vw"]}
                        isVisible={isVisible}
                    />
                </GridItem>
            ))}
        </Grid>
    );
};
