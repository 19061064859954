import { styles } from "./styles";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { IconButton } from "@ui/components/forms/icon-button/IconButton";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { isEmpty } from "lodash-es";
import type { FC } from "react";
import type { ILink } from "@contentful-api/types/contentful";
import { Link } from "@components/cms/link/Link";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { CommonCMS } from "@lib/constants/contentful";
import { space } from "ui/src/design-tokens";

interface ISocialMediaWidgetProps {
    socialLinks?: ILink[];
}

const SocialMediaWidget: FC<ISocialMediaWidgetProps> = ({ socialLinks }) => {
    const { get: getMicrocopy } = useMicrocopy();
    if (isEmpty(socialLinks)) return null;
    return (
        <Box sx={styles.container}>
            <p>{getMicrocopy(CommonCMS.shared, CommonCMS.followUs)}</p>
            <Flex mt={3} gap={space["2"]}>
                {socialLinks?.map((socialLink) => (
                    <Link link={socialLink} key={socialLink?.sys?.id}>
                        <IconButton
                            aria-label={socialLink?.fields?.label}
                            color="black"
                            bg={"transparent"}
                            icon={<ECCOIcon name={socialLink?.fields?.label?.toLowerCase()} />}
                            size="mb"
                        />
                    </Link>
                ))}
            </Flex>
        </Box>
    );
};

export default SocialMediaWidget;
