import { Text } from "@ui/components/content/text/Text";
import { Flex } from "@ui/components/layout/flex/Flex";
import { Button } from "@ui/components/forms/button/Button";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { CommonCMS } from "@lib/constants/contentful";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { space } from "ui/src/design-tokens";
import { ModalTypes } from "@lib/enums/ModalTypes";
import type { Session } from "next-auth";
import { bottomContainerMobileStyles } from "@components/layouts/navigation/styles";
import LogoutButton from "@components/auth/LogOutButton";

interface MobileNavigationContainerProps {
    isAuthenticated: boolean;
    session: Session;
    showModal: (modalType: ModalTypes) => void;
}

export const MobileBottomContainer = ({
    isAuthenticated,
    session,
    showModal,
}: MobileNavigationContainerProps) => {
    const { get: getMicrocopy } = useMicrocopy();

    const loggedInAsMicrocopy = getMicrocopy(CommonCMS.shared, CommonCMS.loggedInAs, {
        replacements: { name: session?.user?.name },
    })?.split(":");
    const StyledMicroCopy = () => {
        return (
            <Flex gap={1}>
                <Text
                    fontSize={"desktopBodyTextTiny"}
                    color="gray.200"
                    whiteSpace={"normal"}
                    fontWeight="semibold"
                >
                    {loggedInAsMicrocopy?.[0]}
                </Text>
                <Text
                    fontSize={"desktopBodyTextTiny"}
                    color="black"
                    whiteSpace={"normal"}
                    fontWeight="semibold"
                >
                    {loggedInAsMicrocopy?.[1]}
                </Text>
            </Flex>
        );
    };

    return (
        <Flex sx={bottomContainerMobileStyles}>
            {isAuthenticated ? (
                <>
                    <StyledMicroCopy />
                    <LogoutButton variant="standard" fontSize={"desktopBodyTextTiny"} />
                </>
            ) : (
                <>
                    <Text
                        fontSize={"desktopBodyTextTiny"}
                        color="black"
                        whiteSpace={"normal"}
                        fontWeight="bold"
                    >
                        {getMicrocopy(CommonCMS.shared, CommonCMS.ECCOMember)}
                    </Text>
                    <Flex flexDir={"row"} gap={space["0.2"]}>
                        <Button
                            rightIcon={<ECCOIcon name="arrow-right" />}
                            variant={"standard"}
                            onClick={() => showModal(ModalTypes.createAccount)}
                        >
                            <span>
                                {getMicrocopy(CommonCMS.shared, CommonCMS.button.createAccount)}
                            </span>
                        </Button>
                        <Button
                            rightIcon={<ECCOIcon name="arrow-right" />}
                            variant={"standard"}
                            onClick={() => showModal(ModalTypes.login)}
                        >
                            <span>{getMicrocopy(CommonCMS.shared, CommonCMS.button.logIn)}</span>
                        </Button>
                    </Flex>
                </>
            )}
        </Flex>
    );
};
